<template>
<div class="resume">
        <div class="title">
            <div class="sub-title">数据上报</div>
            <!-- <div class="sub-filtter"><i class="el-icon-delete"></i> 清空索索条件</div> -->
        </div>
        <el-card class="box-card" style="min-height:100%; padding:30px 0;">
            <div class="nowmonth"><el-button size="small" style="margin-right:8px;" @click="prevyear">上一年</el-button> 当前年份：{{showyear}}年 <el-button style="margin-left:8px;" size="small" @click="nextyear">下一年</el-button></div>
            <div class="monthbox">
                <div class="monthitem" @click="gotoList(1)" :class="showyear>year || (showyear==year && month<1)?'noallow':''">
                    1<span>月</span>
                </div>
                <div class="monthitem" @click="gotoList(2)" :class="showyear>year || (showyear==year && month<2)?'noallow':''">
                    2<span>月</span>
                </div>
                <div class="monthitem" @click="gotoList(3)" :class="showyear>year || (showyear==year && month<3)?'noallow':''">
                    3<span>月</span>
                </div>
                <div class="monthitem" @click="gotoList(4)" :class="showyear>year || (showyear==year && month<4)?'noallow':''">
                    4<span>月</span>
                </div>
                <div class="monthitem" @click="gotoList(5)" :class="showyear>year || (showyear==year && month<5)?'noallow':''">
                    5<span>月</span>
                </div>
                <div class="monthitem" @click="gotoList(6)" :class="showyear>year || (showyear==year && month<6)?'noallow':''">
                    6<span>月</span>
                </div>
                <div class="monthitem" @click="gotoList(7)" :class="showyear>year || (showyear==year && month<7)?'noallow':''">
                    7<span>月</span>
                </div>
                <div class="monthitem" @click="gotoList(8)" :class="showyear>year || (showyear==year && month<8)?'noallow':''">
                    8<span>月</span>
                </div>
                <div class="monthitem" @click="gotoList(9)" :class="showyear>year || (showyear==year && month<9)?'noallow':''">
                    9<span>月</span>
                </div>
                <div class="monthitem" @click="gotoList(10)" :class="showyear>year || (showyear==year && month<10)?'noallow':''">
                    10<span>月</span>
                </div>
                <div class="monthitem" @click="gotoList(11)" :class="showyear>year || (showyear==year && month<11)?'noallow':''">
                    11<span>月</span>
                </div>
                <div class="monthitem" @click="gotoList(12)" :class="showyear>year || (showyear==year && month<12)?'noallow':''">
                    12<span>月</span>
                </div>
            </div>
        </el-card>
</div>

</template>
<script>
export default {
    data(){
        return{
            year:"",
            month:"",
            day:"",
            showyear:"",
            showmonth:"",
            
        }
    },
    created(){
        var date = new Date();
        this.year=date.getFullYear();
        let zsm=date.getMonth()+1;
        this.month=zsm<=9?'0'+zsm:zsm
        let zsd=date.getDate();
        this.day=zsd<=9?'0'+zsd:zsd
        this.showyear=this.year
        this.showmonth=zsm
        
    },
    methods:{
        gotoList(m){
            if(this.showyear>this.year){
                this.$message.error("时间还没有达到！")
                return
            }
            if(this.showyear==this.year){
                if(this.month<m){
                    this.$message.error("时间还没有达到！")
                    return
                }
            }
            this.$router.push({path:"/company/datalist",query:{y:this.showyear,m:m}})
        },
        prevyear(){
            this.showyear=(this.showyear*1)-1
        },
        nextyear(){
            this.showyear=(this.showyear*1)+1
        },
    }
}
</script>
<style scoped>
.monthbox{ margin:18px auto; display: flex; justify-content: space-between;flex-wrap: wrap; width: 601px;border-bottom: #f0f0f0 solid 1px; border-right: #f0f0f0 solid 1px;}
.monthitem{ width: 200px; cursor: pointer; font-size: 24px; text-align: center; height: 160px; box-sizing: border-box; border-top: #f0f0f0 solid 1px; border-left: #f0f0f0 solid 1px;}
.monthitem span{font-size: 14px; margin-left:5px; color:#999; line-height: 160px;}
.monthitem:hover{ color:#409eff;}
.monthitem:hover span{ color:#409eff;}
.noallow{ cursor: not-allowed; background: #f5f5f5;}
.noallow:hover{ color:#555;}
.noallow:hover span{ color:#999;}
.nowmonth{ text-align: center; font-size: 18px; font-weight: bold; padding:0 0 15px 0;}
</style>